import { useState, useEffect, createContext, useContext } from 'react';
import { Amplify, Auth, Hub } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import { type ICredentials, type HubCallback } from '@aws-amplify/core';
import {
  CognitoAccessToken,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import * as Sentry from '@sentry/react';
import { useLocation, useNavigate } from 'react-router';

import awsExports from '../aws-exports.json';
import {
  getCognitoAttrFromStorage,
  isExpiredSessionError,
  isTokenFromAzureOIDC,
} from '../utils/auth';
import { UnauthenticatedRoute } from '../utils/route';
import { removeManageUsersFilterSessionItems } from '../utils/sessionStorage';
const GRAPHQL_MESH_ENABLED =
  import.meta.env.VITE_GRAPHQL_MESH_ENABLED === 'true';

const amplifyOptions = {
  // Amplify Auth Configuration
  aws_cognito_region: awsExports.region,
  aws_user_pools_id: awsExports.pool_id,
  aws_user_pools_web_client_id: awsExports.client_id,
  oauth: {
    redirectSignIn: `${window.location.origin}/`,
    redirectSignOut: `${window.location.origin}/`,
    responseType: 'token',
    domain: `${awsExports.cognito_domain}.auth.${awsExports.region}.amazoncognito.com`,
  },
};

export const configureAmplify = () => {
  // Dynamically determines the client id for the current sso flow
  // Note: we assume this will only be triggered via Cognito hosted UI
  // so regular users will never hit this route.
  const ssoClientId = (() => {
    if (
      // The callback url will be of the form /sso/:clientId
      // which is captured in this condition.
      // This also stores the client id in local storage.
      window.location.pathname.includes('/sso/') &&
      window.location.pathname.split('/').filter((s) => s).length > 1
    ) {
      const ssoClientIdInUrl =
        window.location.pathname.split('/').filter((s) => s)[1] ?? '';

      localStorage.setItem('sso-client-id', ssoClientIdInUrl);

      return ssoClientIdInUrl;
    } else if (
      // If the user accesses the regular auth flow, then we
      // assume they are not accessing through an sso flow.
      // Hence we remove the client id from local storage and use
      // the default one
      window.location.pathname.includes('/sign-in') ||
      window.location.pathname.includes('/sign-up')
    ) {
      localStorage.removeItem('sso-client-id');
    } else if (localStorage.getItem('sso-client-id')) {
      return localStorage.getItem('sso-client-id');
    }
  })();

  const options = ssoClientId
    ? {
        ...amplifyOptions,
        aws_user_pools_web_client_id: ssoClientId,
      }
    : amplifyOptions;

  Amplify.configure(options);
};

configureAmplify();

export interface IUser {
  username: string;
  email: string;
  tenantId: string;
  userId: string;
  token?: CognitoAccessToken;
  cognitoUser?: CognitoUser;
  error?: string;
}

export interface ICognitoUser {
  username: string;
  password: string;
  attributes: {
    email: string;
  };
  clientMetadata: {
    tenant_code: string;
    invite_code: string;
    email_consent: boolean;
    locale: string;
  };
}

type SignOutParams = {
  tenantCode?: string;
  onSignOut?: () => void;
};

export interface IConfirmUser {
  username: string;
  clientMetadata: {
    tenant_code: string;
    locale: string;
  };
}
export interface IResetUser extends IConfirmUser {
  code: string;
  new_password: string;
}

interface IAuthContext {
  isLoading: boolean;
  user: IUser;
  signUp: ({
    username,
    password,
    attributes,
    clientMetadata,
  }: ICognitoUser) => Promise<any>;
  signIn(
    username: string,
    password: string,
    clientMetadata?: { tenant_code?: string }
  ): Promise<CognitoUser>;
  signOut(params: SignOutParams): Promise<any>;
  confirmSignUp(
    username: string,
    confirmationCode: string,
    tenantCode?: string,
    inviteCode?: string,
    emailConsent?: boolean
  ): Promise<any>;
  resendSignUp(
    username: string,
    clientMetadata: { tenant_code?: string; invite_code?: string }
  ): Promise<any>;
  forgotPassword({ username, clientMetadata }: IConfirmUser): Promise<any>;
  forgotPasswordSubmit({
    username,
    code,
    new_password,
    clientMetadata,
  }: IResetUser): Promise<any>;
  completeNewPassword(user: CognitoUser, newPassword: string): Promise<void>;
  refreshCache: () => Promise<void>;
  getSession(): Promise<CognitoUserSession | null>;
  checkExistence: (email: string) => Promise<boolean>;
  isOnboarded: boolean;
  federatedSignIn: () => Promise<ICredentials>;
  changePassword: (oldPassword: string, newPassword: string) => Promise<any>;
}

const signIn = (
  username: string,
  password: string,
  clientMetadata?: { tenant_code?: string }
): Promise<CognitoUser> =>
  Auth.signIn(username, password, clientMetadata)
    .then((user: CognitoUser) => {
      Sentry.setUser({ username: user.getUsername() });
      return user;
    })
    .catch((e) => {
      throw e;
    });

const signUp = ({
  username,
  password,
  attributes,
  clientMetadata,
}: ICognitoUser): Promise<any> =>
  Auth.signUp({
    username,
    password,
    attributes,
    clientMetadata: {
      tenant_code: clientMetadata.tenant_code || '',
      invite_code: clientMetadata.invite_code || '',
      email_consent: (clientMetadata.email_consent || false).toString(),
      locale: clientMetadata.locale,
    },
  }).catch((e) => {
    throw e;
  });

const signOut = ({ onSignOut, tenantCode }: SignOutParams): Promise<any> => {
  tenantCode && sessionStorage.setItem('ffai_tenant_temp', tenantCode);

  // For immediate logout for API access, due to Cognito JWT lifetime.
  addTokenToRevokedList();

  return Auth.signOut().then(() => {
    Sentry.configureScope((scope) => scope.setUser(null));
    (window as any).Intercom('shutdown');
    onSignOut && onSignOut();
  });
};

/**
 * Add the token to the revoked token list in access control.
 * We do this to block API calls immediately after logout: the Cognito JWT cannot be invalidated immediately.
 */
const addTokenToRevokedList = async () => {
  try {
    const session = await getSession();
    const response = await fetch(`${import.meta.env.VITE_GRAPHQL_HOST}`, {
      method: 'POST',
      headers: {
        Authorization: `${session?.getAccessToken().getJwtToken()}`,
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        variables: {
          token: `${session?.getAccessToken().getJwtToken()}`,
        },
        query: `
        mutation RevokeToken($token: String) {
          revokeToken(token: $token)
        }
        `,
      }),
    });
    const responseJson = await response.json();
    responseJson.errors && console.error(responseJson.errors);
  } catch (error) {
    // Do not prevent logout in the event of revokeToken failure.
    console.error('Error calling revokeToken', error);
  }
};

const federatedSignIn = () => {
  return Auth.federatedSignIn();
};

const confirmSignUp = (
  username: string,
  confirmationCode: string,
  tenantCode?: string,
  inviteCode?: string,
  emailConsent?: boolean
): Promise<any> =>
  Auth.confirmSignUp(username, confirmationCode, {
    clientMetadata: {
      tenant_code: tenantCode || '',
      invite_code: inviteCode || '',
      email_consent: (emailConsent ?? true).toString(),
    },
  }).then(
    () =>
      (window.document.location = tenantCode
        ? `/sign-in?tc=${tenantCode}&ic=${inviteCode}`
        : '/sign-in')
  );

const resendSignUp = (
  username: string,
  clientMetadata: { tenant_code?: string; invite_code?: string; locale: string }
): Promise<any> =>
  Auth.resendSignUp(username, clientMetadata).catch((e) => {
    throw e;
  });

const completeNewPassword = async (
  user: CognitoUser,
  newPassword: string
): Promise<void> => {
  return Auth.completeNewPassword(user, newPassword);
};

const forgotPassword = ({
  username,
  clientMetadata,
}: IConfirmUser): Promise<any> =>
  Auth.forgotPassword(username, clientMetadata)
    .then(() => {
      const tenantCode = clientMetadata.tenant_code;
      window.document.location = tenantCode
        ? `/reset-password?tc=${tenantCode}&u=${encodeURIComponent(username)}`
        : `/reset-password?u=${encodeURIComponent(username)}`;
    })
    .catch((e) => {
      if (e.code === 'UserLambdaValidationException') {
        throw e;
      }

      // Security: do not indicate if email exists.
      const tenantCode = clientMetadata.tenant_code;
      window.document.location = tenantCode
        ? `/reset-password?tc=${tenantCode}&u=${encodeURIComponent(username)}`
        : `/reset-password?u=${encodeURIComponent(username)}`;
    });

const forgotPasswordSubmit = ({
  username,
  code,
  new_password,
  clientMetadata,
}: IResetUser): Promise<any> =>
  Auth.forgotPasswordSubmit(username, code, new_password, clientMetadata)
    .then(() => {
      window.document.location = clientMetadata.tenant_code
        ? `/sign-in?tc=${clientMetadata.tenant_code}`
        : '/sign-in';
    })
    .catch((e) => {
      throw e;
    });

const refreshCache = async () => {
  await Auth.currentAuthenticatedUser({ bypassCache: true });
};

const getSession = (): Promise<CognitoUserSession | null> =>
  Auth.currentSession();

const changePassword = (
  oldPassword: string,
  newPassword: string
): Promise<any> =>
  Auth.currentAuthenticatedUser()
    .then((user) => {
      return Auth.changePassword(user, oldPassword, newPassword);
    })
    .catch((err) => {
      throw err;
    });

/**
 * Check if an email already exists in our Cognito user pool.
 * Utilize the Cognito error got from fake sign-in to determine whether an email exists (unauthorized, required pw reset), or doesn't exist (not found, not confirmed) in our Cognito user pool.
 * @param email
 * @returns boolean // whether email already exists in our Cognito user pool
 */
const checkExistence = (email: string): Promise<boolean> => {
  return Auth.signIn(email.toLowerCase(), '123')
    .then(() => {
      return false;
    })
    .catch((error) => {
      const code = error.code;
      switch (code) {
        case 'NotAuthorizedException':
        case 'PasswordResetRequiredException':
          return true;
        case 'UserNotFoundException':
        case 'UserNotConfirmedException':
        default:
          return false;
      }
    });
};

/**
 * Empty user object to replicate a null value.
 * This is used just to make `user` always defined so that
 * we can access its attributes more conveniently
 * @example
 * const { user: { tenantId, userId } } = useAuth();
 * const f = tenantId // this is of type `string` ✅
 *
 * // INSTEAD OF
 *
 * const { user } = useAuth();
 * const f = user?.tenantId // this is of type `string | undefined` 🤮
 */
const emptyUser: IUser = { email: '', tenantId: '', userId: '', username: '' };

export const useCognito = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState<IUser>(emptyUser);
  const [isLoading, setIsLoading] = useState(false);
  const [isOnboarded, setIsOnboarded] = useState(false);

  /*
   * When a page is redirected, the original (authenticated) url can be identified via a `location.key = 'default'` and its pathname.
   *
   * This if-statement determines whether this unauthetnicated user is redirected to sign-in page from an authenticated url.
   * If yes, we will store this in local storage, which will be used for later in SignIn functions.
   */
  useEffect(() => {
    if (
      location.key === 'default' &&
      location.pathname !== UnauthenticatedRoute.SIGN_IN
    ) {
      window.localStorage.setItem('redirectedFrom', location.pathname);
    }
  }, []);

  const loadUserProfile = async () => {
    let session;
    try {
      setIsLoading(true);
      session = await getSession();

      if (session && session.isValid()) {
        const userInfo = await Auth.currentUserInfo();
        const accessToken = session.getAccessToken();

        if (!accessToken.payload['cognito:groups']) {
          await Auth.signOut();
          setUser({ ...emptyUser, error: 'MissingUserGroup' });
          setIsLoading(false);
          return;
        }

        setUser({
          email: userInfo.attributes.email,
          username: userInfo.username,
          userId: accessToken.payload.sub,
          tenantId: JSON.parse(accessToken.payload['cognito:groups'][0])
            .tenant_id,
          token: accessToken,
        });
      } else if (isTokenFromAzureOIDC(session?.getIdToken().payload)) {
        Auth.federatedSignIn();
      }
    } catch (e: any) {
      setIsLoading(false);

      if (isExpiredSessionError(e.message)) {
        const idToken = getCognitoAttrFromStorage('idToken');

        if (isTokenFromAzureOIDC(idToken)) {
          Auth.federatedSignIn();
        }
      } else {
        session = await getSession();

        if (isTokenFromAzureOIDC(await session?.getIdToken().payload)) {
          Auth.federatedSignIn();
        }
      }

      return;
    }

    try {
      const appSyncHost = `${import.meta.env.VITE_GRAPHQL_HOST}`.split('/')[2];
      const response = await fetch(`${import.meta.env.VITE_GRAPHQL_HOST}`, {
        method: 'POST',
        headers: {
          Authorization: `${session?.getAccessToken().getJwtToken()}`,
          host: appSyncHost, // the middle bits of the URL
          'Content-type': 'application/json;charset=UTF-8',
        },
        body: JSON.stringify({
          variables: {
            locale: 'en-US',
            user_uuid: session?.getAccessToken().payload.sub, //
          },
          query: !GRAPHQL_MESH_ENABLED
            ? `
              query GetUserProfile($locale: String!, $user_uuid: String!) {\n  getUserProfile(query: {locale: $locale, user_uuid: $user_uuid}) {\n    is_onboarded\n    user_uuid\n    __typename\n  }\n}
              `
            : `
              query GetUserProfile($locale: String!, $user_uuid: String!) {\n  getUserProfile(getProfileInput: {locale: $locale, user_uuid: $user_uuid}) {\n    is_onboarded\n    user_uuid\n    __typename\n  }\n}
              `,
        }),
      });
      const json = await response.json();
      setIsOnboarded(json.data.getUserProfile.is_onboarded || false);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  /**
   * If an authenticated user signing in via a url redirection from an authenticated url, this user will be navigated to the original authenticated url (that he/ she was planning to navigate to) upon signing in successfully.
   *
   * If an authenticated user is not signing in via url redirection, this user will navigate into the the root authenticated page as usual.
   */
  const signInUrlRedirection = () => {
    const redirectedFrom = window.localStorage.getItem('redirectedFrom');
    if (redirectedFrom) {
      navigate(redirectedFrom);
    } else {
      navigate('/');
    }
  };

  const authListener: HubCallback = async ({ payload: { event, data } }) => {
    switch (event) {
      case 'signIn': {
        const accessToken = data.signInUserSession.accessToken;

        await setUser({
          username: data.username,
          tenantId: JSON.parse(accessToken.payload['cognito:groups'][0])
            .tenant_id,
          userId: accessToken.payload.sub,
          token: accessToken,
          email: data.attributes.email,
        });
        loadUserProfile();
        signInUrlRedirection();
        break;
      }
      case 'signUp':
        window.localStorage.removeItem('emailConsent');
        break;
      case 'signOut':
        setUser(emptyUser);
        window.localStorage.removeItem('redirectedFrom');
        removeManageUsersFilterSessionItems();
        break;
    }
  };

  useEffect(() => {
    loadUserProfile();
  }, []);

  useEffect(() => {
    const cancel = Hub.listen('auth', authListener);
    return () => cancel();
  }, []);

  return {
    isLoading,
    user,
    signUp,
    signIn,
    signOut,
    confirmSignUp,
    resendSignUp,
    forgotPassword,
    forgotPasswordSubmit,
    completeNewPassword,
    getSession,
    checkExistence,
    refreshCache,
    isOnboarded,
    changePassword,
    federatedSignIn,
  };
};

const AuthContext = createContext<IAuthContext>({
  isLoading: false,
  user: emptyUser,
  signUp,
  signIn,
  signOut,
  confirmSignUp,
  resendSignUp,
  forgotPassword,
  getSession,
  forgotPasswordSubmit,
  completeNewPassword,
  isOnboarded: false,
  changePassword,
  checkExistence,
  refreshCache,
  federatedSignIn,
});

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider: React.FC = ({ children }) => {
  const auth = useCognito();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
